import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function() {
  // Shirt form options

    // Change shirt and sleeve svg colours on radio button change
      $('#shirt-colour-display').css('background-color', '#dd0000');
      $('#shirt-alt-display').css('background-color', '#1a237e');

      $("#frame-shirt-form").on('change','input[type="color"][name="shirt-colour"]', (function() { 
        var color = $(this).val();
        $('.shirt-fill').css('fill', color);
        $('#shirt-colour-display').css('background-color', color);
      }));

      $("#frame-shirt-form").on('change','input[type="color"][name="alternate-colour"]', (function() { 
        var color = $(this).val();
        $('.alt-fill').css('fill', color);
        $('#shirt-alt-display').css('background-color', color);
      }));

    // Change shirt svg to back or front on click
      $('#frame-shirt-form').on('change', 'input[type="radio"][name="display-shirt"]', function(){
        if($('#display-shirt-back').is(':checked')) {
          $('#shirt-back').removeClass('-hide-svg');
          $('#shirt-back-fill').removeClass('-hide-svg');
          $('#shirt-outline-back').removeClass('-hide-svg');
          $('#shirt-collar').addClass('-hide-svg');
          $('#shirt-outline').addClass('-hide-svg');
          $('#shirt-inside').addClass('-hide-svg');
        } else {
          $('#shirt-back').addClass('-hide-svg');
          $('#shirt-back-fill').addClass('-hide-svg');
          $('#shirt-outline-back').addClass('-hide-svg');
          $('#shirt-collar').removeClass('-hide-svg');
          $('#shirt-outline').removeClass('-hide-svg');
          $('#shirt-inside').removeClass('-hide-svg');
        };
      });


    // Change shirt style
      $("#frame-shirt-form").on('change','input[type="radio"][name="shirt-style"]', (function() { 
        var style = $(this).attr('data-name');
        if ( style == 'shirt-vertical') {
          $('.shirt-vertical').removeClass('-hide-svg');
          $('.sleeves-vertical').removeClass('-hide-svg');
          $('.sleeves-horizontal').addClass('-hide-svg');
          $('.shirt-horizontal').addClass('-hide-svg');
          $('.shirt-collar').removeClass('alt-fill').addClass('shirt-fill');
          var baseColour = $('.shirt-fill').css('fill');
          $('.shirt-collar').css('fill', baseColour);
        } else if ( style == 'shirt-horizontal') {
          $('.shirt-horizontal').removeClass('-hide-svg');
          $('.sleeves-horizontal').removeClass('-hide-svg');
          $('.sleeves-vertical').addClass('-hide-svg');
          $('.shirt-vertical').addClass('-hide-svg');
          $('.shirt-collar').removeClass('alt-fill').addClass('shirt-fill');
          var baseColour = $('.shirt-fill').css('fill');
          $('.shirt-collar').css('fill', baseColour);
        } else if ( style == 'shirt-collar') {
          $('.shirt-collar').addClass('alt-fill').removeClass('shirt-fill');
          var baseColour = $('.alt-fill').css('fill');
          $('.shirt-collar').css('fill', baseColour);
          $('.sleeves-horizontal').addClass('-hide-svg');
          $('.shirt-horizontal').addClass('-hide-svg');
          $('.sleeves-vertical').addClass('-hide-svg');
          $('.shirt-vertical').addClass('-hide-svg');
        }
      }));

    // Show or hide sleeves of svg shirt on radio button change
      $('#frame-shirt-form').on('change', 'input[type="radio"][name="display-sleeves"]', function(){
        if($('#displaySleevesYes').is(':checked')) {
          $('#sleeves-l').removeClass('-hide-svg');
          $('#sleeves-r').removeClass('-hide-svg');
        } else {
          $('#sleeves-l').addClass('-hide-svg');
          $('#sleeves-r').addClass('-hide-svg');
        };
      });


  // Frame form options

    // Change frame colour on radio button change
      $(".variations_form").on('change','input[type="radio"][name="addon-46-frame-colour-0[]"]', (function() { 
        var color = $(this).val();
        $('#frame-svg').removeClass("-colour-white -colour-black -colour-grey -colour-navy -colour-green -colour-red -colour-gold -colour-silver").addClass('-colour-' + color);
        $('.m-frame-form-check-frame').removeClass('-selected');
        var checkID = $(this).attr('id');
        $("#icon-"+checkID).addClass('-selected');
      }));
    
    // Change frame background colour on radio button change
      $('#frame-svg').addClass("black");
      
      $(".variations_form").on('change','input[type="radio"][name="addon-46-background-colour-1[]"]', (function() { 
        var color = $(this).val();
        $('#frame-svg').removeClass("white black crimson blue dark-grey dawn-grey pompadour red antique-white").addClass(color);
        $('.m-frame-form-check-bg').removeClass('-selected');
        var checkID = $(this).attr('id');
        $("#icon-"+checkID).addClass('-selected');
      }));

    // Change spacer svg on dropdown change
      $("#spacer-black").removeClass("-hide-svg");

      $('.variations_form').on('change','input[type="radio"][name="addon-46-spacer-colour-2[]"]', (function() {
        $(".m-frame-image-spacer-svg").addClass("-hide-svg");
        var spacerID = $(this).val();
        $("#spacer-"+spacerID).removeClass("-hide-svg");
        $('.m-frame-form-check-spacer').removeClass('-selected');
        var checkID = $(this).attr('id');
        $("#icon-"+checkID).addClass('-selected');
      }));

    // Change image upload icon to a tick when file is chosen
      $(".variations_form").on('change','input[type="file"][name="addon-46-image-1-7"]', (function() { 
        if ($('#addon-46-image-1-7').val()) {
          if(this.files[0].size > 41943040) {
            $(this).val('');
            $('#error-46-image-1-7').show();
            $("#placeholder-svg-1").removeClass('-hide-svg');
          } else {
            $('#error-46-image-1-7').hide();
            readURL(this);
            $("#46-image-1-7-imageCross").addClass('-no-display');
            $("#46-image-1-7-imageTick").removeClass('-no-display');
            $("#46-image-1-7-imageCross").closest('.m-frame-form-upload-label').addClass('-selected');
            $("#placeholder-svg-1").addClass('-hide-svg');
          };
        } else {
          $("#46-image-1-7-imageCross").removeClass('-no-display');
          $("#46-image-1-7-imageTick").addClass('-no-display');
          $("#46-image-1-7-imageCross").closest('.m-frame-form-upload-label').removeClass('-selected');
          $("#placeholder-svg-1").removeClass('-hide-svg');
          resetURL(this);
        };
      }));

      $(".variations_form").on('change','input[type="file"][name="addon-46-image-2-8"]', (function() { 
        if ($('#addon-46-image-2-8').val()) {
          if(this.files[0].size > 41943040) {
            $(this).val('');
            $('#error-46-image-2-8').show();
          } else {
            $('#error-46-image-2-8').hide();
            readURL2(this);
            $("#46-image-2-8-imageCross").addClass('-no-display');
            $("#46-image-2-8-imageTick").removeClass('-no-display');
            $("#46-image-2-8-imageCross").closest('.m-frame-form-upload-label').addClass('-selected');
            $("#placeholder-svg-2").addClass('-hide-svg');
          };
        } else {
          $("#46-image-2-8-imageCross").removeClass('-no-display');
          $("#46-image-2-8-imageTick").addClass('-no-display');
          $("#46-image-2-8-imageCross").closest('.m-frame-form-upload-label').removeClass('-selected');
          $("#placeholder-svg-2").removeClass('-hide-svg');
          resetURL(this);
        };
      }));

    // Change team image upload icon to a tick when logo is chosen
      $(".variations_form").on('change','input[type="file"][name="addon-46-team-logo-5"]', (function() { 
        if ($('#addon-46-team-logo-5').val()) {
          if(this.files[0].size > 41943040) {
            $(this).val('');
            $('#error-46-team-logo-5').show();
          } else {
            $("#46-team-logo-5-imageCross").addClass('-no-display');
            $("#46-team-logo-5-imageTick").removeClass('-no-display');
            $("#46-team-logo-5-imageCross").closest('.m-frame-form-upload-label').addClass('-selected');
            $('#error-46-team-logo-5').hide();
          }; 
        };
      }));

    // Show plaque text field when plaque option is chosen
      $(".wc-pao-addon-plaque-text").addClass('-no-display');
      $("#plaque-text").append('<em class="required" title="Required field">*</em>');
      $("#plaque-text .required").hide();
      $('.plaque-none').addClass('-focus');

      $(".variations_form").on('change','input[type="radio"][name="addon-46-plaque-team-logo-3[]"]', (function() { 
        if ( $('#46-plaque-team-logo-3-0').is(':checked') || $('#46-plaque-team-logo-3-1').is(':checked')) {
          $(".wc-pao-addon-plaque-text").removeClass('-no-display');
          $("#addon-46-plaque-text-4").prop('required',true);
          $("#plaque-text .required").show();
          $('.m-frame-form-check-plaque').removeClass('-selected');
          var checkID = $(this).attr('id');
          $("#icon-"+checkID).addClass('-selected');
          $('.plaque-none').removeClass('-focus');
        } else {
          $(".wc-pao-addon-plaque-text").addClass('-no-display');
          $("#addon-46-plaque-text-4").prop('required',false);
          $("#plaque-text .required").hide();
          $('.m-frame-form-check-plaque').removeClass('-selected');
          $('.plaque-none').addClass('-focus');
        }
      }));

    // Show team logo upload field when plaque option is chosen
      $(".wc-pao-addon-team-logo").addClass('-no-display');

      $(".variations_form").on('change','input[type="radio"][name="addon-46-plaque-team-logo-3[]"]', (function() { 
        if ( $('#46-plaque-team-logo-3-0').is(':checked') || $('#46-plaque-team-logo-3-1').is(':checked')) {
          $(".wc-pao-addon-team-logo").removeClass('-no-display');
        } else {
          $(".wc-pao-addon-team-logo").addClass('-no-display');
        };
      }));

    // Show or hide correct plaque svg on form click
      $(".variations_form").on('change','input[type="radio"][name="addon-46-plaque-team-logo-3[]"]', (function() { 
        if ( $('#46-plaque-team-logo-3-0').is(':checked')) {
          $('#gold-plaque').removeClass('-hide-svg');
        } else {
          $('#gold-plaque').addClass('-hide-svg');
        };

        if ( $('#46-plaque-team-logo-3-1').is(':checked')) {
          $('#silver-plaque').removeClass('-hide-svg');
        } else {
          $('#silver-plaque').addClass('-hide-svg');
        };

        if ( $('#plaque-noSelection').is(':checked')) {
          $('#silver-plaque').addClass('-hide-svg');
          $('#gold-plaque').addClass('-hide-svg');
        };
      }));

    // Show image upload options on radio button change
      $(".variations_form").on('change','input[type="radio"][name="addon-46-images-6[]"]', (function() { 
        if ( $('#46-images-6-0').is(':checked')) {
          $('.m-frame-form-images-label').removeClass('-focus');
          $(this).closest('.m-frame-form-images-label').addClass('-focus');
          $(".wc-pao-addon-image-1").addClass('-showImageUpload');
          $(".wc-pao-addon-image-2").addClass('-showImageUpload');
          // Make image uploaded required
          $("#addon-46-image-1-7").prop('required',true);
          $("#addon-46-image-2-8").prop('required',true);
          $("#image-1 .required").show();
          $("#image-2 .required").show();
          // Show placeholder svg on frame image
          $('#placeholder-svg-1').removeClass('-hide-svg');
          $('#placeholder-svg-2').removeClass('-hide-svg');
        } else {
          $('.m-frame-form-images-label').removeClass('-focus');
          $(this).closest('.m-frame-form-images-label').addClass('-focus');
          $(".wc-pao-addon-image-1").removeClass('-showImageUpload');
          $(".wc-pao-addon-image-2").removeClass('-showImageUpload');
          // Remove required from image uploads
          $("#addon-46-image-1-7").prop('required',false);
          $("#addon-46-image-2-8").prop('required',false);
          $("#image-1 .required").hide();
          $("#image-2 .required").hide();
          // Hide placeholder svg on frame image
          $('#placeholder-svg-1').addClass('-hide-svg');
          $('#placeholder-svg-2').addClass('-hide-svg');
        };
      }));

      // Add * to image upload headings and hide
      $("#image-1").append('<em class="required" title="Required field">*</em>');
      $("#image-2").append('<em class="required" title="Required field">*</em>');
      $("#image-1 .required").hide();
      $("#image-2 .required").hide();

      // Change shirt position on frame if plaque or images are added
      $(".variations_form").on('change', (function() { 
        if ( $('#46-images-6-0').is(':checked') || $('#46-plaque-team-logo-3-0').is(':checked') || $('#46-plaque-team-logo-3-1').is(':checked')) {
          $('.m-frame-shirt-sleeves').removeClass('-onlyShirt');
          $('.m-frame-shirt-svg').removeClass('-onlyShirt');
        } else {
          $('.m-frame-shirt-sleeves').addClass('-onlyShirt');
          $('.m-frame-shirt-svg').addClass('-onlyShirt');
        };
      }));

  // Change hidden woocommerce add-on field when shirt form "display sleeves" radio button is changed
    $('#46-display-sleeves-14-1').prop('checked', true);
    
    $('#frame-shirt-form').on('change', 'input[type="radio"][name="display-sleeves"]', function(){
      if ( $('#displaySleevesYes').is(':checked')) {
        $('#46-display-sleeves-14-0').prop('checked', true);
      };
    
      if ( $('#displaySleevesNo').is(':checked')) {
        $('#46-display-sleeves-14-1').prop('checked', true);
      };
    });
  
  // Change hidden woocommerce add-on field when shirt form "display shirt" radio button is changed
    $('#46-display-shirt-13-0').prop('checked', true);

    $('#frame-shirt-form').on('change', 'input[type="radio"][name="display-shirt"]', function(){
      if ( $('#display-shirt-front').is(':checked')) {
        $('#46-display-shirt-13-0').prop('checked', true);
      };
    
      if ( $('#display-shirt-back').is(':checked')) {
        $('#46-display-shirt-13-1').prop('checked', true);
      };
    });

  // Hide variations from basket page
    $("dt.variation-PlaqueTeamLogospanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan2000bdispan").html("Plaque & Team Logo: £20.00 -");
    $("dt.variation-GiftWrappingspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1500bdispan").html("Gift Wrapping: £15.00");
    $("dt.variation-Upgradedeliveryinsurancespanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1000bdispan").html("Upgrade delivery insurance: £10.00");
    $("dt.variation-Framecarekitspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1000bdispan").html("Frame care kit: £10.00");
    $("dt.variation-Imagesspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan2000bdispan").html("Images: £20.00 -");

    if($("dd.variation-Images").text().indexOf('No') > -1) {
      $(".variation-Images").hide();
    };

  // Show or hide infobox on click and hover
    if ($(window).width() < 992) {
      $('.m-addon-desc-icon').on('click', function() {
        $(this).addClass('-showBox').addClass('-showInfo');
        $(this).closest('.m-addon-desc').find('.m-addon-desc-infobox').show().addClass('-showBox');
      });

      $(document).on('mouseup touchend', function(e) {
        var infobox = $('.-showBox').data('infobox');

        if (e.target.getAttribute('infobox') != infobox && !$(infobox).find(e.target).length) {
          $('.m-addon-desc-icon').removeClass('-showBox').removeClass('-showInfo');
          $('.m-addon-desc-icon').closest('.m-addon-desc').find('.m-addon-desc-infobox').hide().removeClass('-showBox');
        };
      });

    } else {
      $('.m-addon-desc-icon').on('mouseover', function () {
        $(this).addClass('-showBox').addClass('-showInfo');
        $(this).closest('.m-addon-desc').find('.m-addon-desc-infobox').show().addClass('-showBox');
      });
      $('.m-addon-desc-icon').on('mouseout', function () {
        $(this).removeClass('-showBox').removeClass('-showInfo');
        $(this).closest('.m-addon-desc').find('.m-addon-desc-infobox').hide().removeClass('-showBox');
      });
    };

    $('.your-order-container').addClass('-loaded').delay(2000).queue(function(next) {
      $("dt.variation-PlaqueTeamLogospanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan2000bdispan").html("Plaque & Team Logo: £20.00");
      $("dt.variation-GiftWrappingspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1500bdispan").html("Gift Wrapping: £15.00");
      $("dt.variation-Upgradedeliveryinsurancespanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1000bdispan").html("Upgrade delivery insurance: £10.00");
      $("dt.variation-Framecarekitspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan1000bdispan").html("Frame care kit: £10.00");
      $("dt.variation-Imagesspanclasswoocommerce-Price-amountamountbdispanclasswoocommerce-Price-currencySymbolpoundspan2000bdispan").html("Images: £20.00");

      if($("dd.variation-Images").text().indexOf('No') > -1) {
        $(".variation-Images").hide();
      };
      next();
    });

  });

// Add image preview to product frame
  function readURL(input) {
    if(input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#previewImg1').attr('src', e.target.result).removeClass('-hidden');
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  function readURL2(input) {
    if(input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#previewImg2').attr('src', e.target.result).removeClass('-hidden');
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

// Reset image src when no file is chosen
  function resetURL(input) {
    var imgID = $(input).attr('id');

    $('.m-frame-image-preview').each(function() {
      var dataImg = $(this).attr('data-img');
      if(dataImg == imgID) {
        $(this).attr('src', null);
        $(this).addClass('-hidden');
      };
    });
  };