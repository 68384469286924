// import 'jquery';
// import 'mmenu-js';

// document.addEventListener(
//     "DOMContentLoaded", () => {
//         const menu = new Mmenu(".mainNav.-mobile", {

//         },
//         {
//             openingInterval: 0,
//             transitionDuration: 0,
//             offCanvas: {
//                 blockUI: false,
//                 clone: false
//             }
//         });

//         const api = menu.API;
//         jQuery(".mobileMenuToggle").click(function (event) {
//             if (!jQuery(this).hasClass("-active")) {
//                 api.open();
//                 jQuery('body, #header').addClass('-navActive');
//                 jQuery(this).addClass("-active");
//             }
//             else{
//                 jQuery('body, #header').removeClass('-navActive');
//                 jQuery(this).removeClass('-active');
//             }
//         });

//         jQuery('.mobileNavClose').click( function () {
//             jQuery('body, #header').removeClass('-navActive');
//             api.close();
//         });

//         api.bind("close:start", function () {
//             jQuery(".mobileMenuToggle").removeClass("-active");
//         });
//     }
// );