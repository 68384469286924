import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-media-vertical-about-frames.-isCarousel').slick({
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
    {
        breakpoint: 1500,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 3,
        slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 3,
        slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 3,
        slidesToScroll: 1,
        }
      },
      {
        breakpoint: 820,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
        slidesToScroll: 1,
        }
      },
      {
        breakpoint: 650,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '120px',
        slidesToShow: 1,
        slidesToScroll: 1,
        }
      },
      {
        breakpoint: 560,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 1,
        slidesToScroll: 1,
        }
      }
    ]
  });