import 'jquery';

const handleControlClick = e => {
    e.preventDefault();

    let el = jQuery(e.currentTarget);
    let componentId = jQuery(e.currentTarget).attr('data-attr-component-id');
    let id = jQuery(e.currentTarget).attr('data-attr-id');
    let contentEl = jQuery(`#accordionContent-${id}`);

    if(contentEl.length > 0) {
        jQuery(el).parent().toggleClass('-active');
        jQuery(el).toggleClass('-active');
        contentEl.slideToggle(125).toggleClass('-active');
    }
};

jQuery(document).ready( function() {
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child').addClass('-active');
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child > .button').addClass('-active');
    //jQuery('.accordionsList:not(.-inner) > .accordion:first-child > .accordionContent').addClass('-active').slideToggle(200);
    jQuery('.accordion > .button').click(handleControlClick);
});