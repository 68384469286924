import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function() {
  $('.wdpgk-type-button').addClass('wdpgk-don-type-button-active');
});

jQuery(function() {
  var loc = window.location.href; // returns the full URL
  if(/checkout/.test(loc)) {
    jQuery('#paymentDetails').addClass('-current');
    jQuery('#paymentDetails').removeClass('-greyed');
  }
});

jQuery(function() {
  var loc = window.location.href; // returns the full URL
  if(/order-received/.test(loc)) {
    jQuery('#confirm').addClass('-current');
    jQuery('#confirm').removeClass('-greyed');
    jQuery('#paymentDetails').removeClass('-current');
    jQuery('#paymentDetails').addClass('-greyed');
  }
});