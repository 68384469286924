import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-frames.-isCarousel').slick({
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
    {
      breakpoint: 1800,
      settings: {
        centerPadding: '100px',
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 1300,
      settings: {
        centerPadding: '180px',
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        centerPadding: '150px',
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1070,
      settings: {
        centerPadding: '120px',
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        centerPadding: '60px',
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 900,
      settings: {
        centerPadding: '0px',
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 750,
      settings: {
        centerPadding: '220px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 710,
      settings: {
        centerPadding: '200px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 670,
      settings: {
        centerPadding: '160px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '150px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 560,
      settings: {
        centerPadding: '120px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '90px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: '60px',
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 380,
      settings: {
        centerPadding: '0px',
        slidesToShow: 1,
      }
    }
    ]
  });
