import { default as $ } from 'jquery';

$("#buttons button").on("click", function(){
    var filtertag = $(this).attr('id');
    $('.m-faq-section').hide().filter('.' + filtertag).show();
})

$("#all").on("click", function(){
    $('.m-faq-section').show();
})

$(document).ready(function(){
    $(".m-faq-section .m-faq-question").click(function(){
        if($(this).closest(".m-faq-section").hasClass("-hide")) {
            $(".m-faq-section").find(".m-faq-answer").slideUp();
            $(this).closest(".m-faq-section").removeClass("-hide");
            $(this).closest(".m-faq-section").find(".m-faq-answer").slideDown();
            $(".fa-chevron-down").removeClass('-upsideDown');
            $(this).closest(".m-faq-section").find(".fa-chevron-down").addClass('-upsideDown');
        }else{
            $(".m-faq-section").addClass("-hide");
            $(".m-faq-answer").addClass("-hide");
            $(".m-faq-section").find(".m-faq-answer").slideUp();
            $(".fa-chevron-down").removeClass('-upsideDown');
        }
   })
 })