import 'jquery';


jQuery('#clickAbout').on('click', function(){
    jQuery('#aboutList').toggleClass('-show-about');
});

jQuery('#clickHelp').on('click', function(){
    jQuery('#helpList').toggleClass('-show-help');
});

jQuery('#clickTouch').on('click', function(){
    jQuery('#touchList').toggleClass('-show-touch');
});