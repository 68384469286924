import { default as $ } from 'jquery';
import 'slick-carousel';

$(document).ready( function() {
    $('.m-media-vertical-alt-reviews.-isCarousel').slick({
        dots: true,
        infinite: true,
        margin: 20,
        slidesToShow: 1,
        arrows: false,
        draggable: false,
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 1,
                    arrows: false,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
      });

});