import 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() > 768) {
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
    };
    
    jQuery('#mobileDropdownButton').on('click', function(){
        jQuery('#dropdownList').toggleClass('-clicked');
        jQuery('#header').toggleClass('-darkMobile');
    });
});

if(jQuery('#body').hasClass('single-product')) {
    jQuery('.m-header-spacer').addClass('product-spacer');
}