// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './accordion/accordion';
import './gallery/gallery';
import './nav/nav';
import './mmenu/mmenu';
import './search/search';
import './header/header';
import './modals/modals';
import './endless-scroll/endless-scroll';
import './cart-summary/cart-summary';
import './basket-update/basket-update';
import './basket-overview/basket-overview';
import './reviews/reviews';
import './animation/animation';
import './footer/footer';
import './frames/frames';
import './frames/frames-vertical';
import './faq/faq';
import './frame-product/frame-product';
import './cards/cards';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
