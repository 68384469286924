import { default as $ } from 'jquery';

$(document).ready(function(){
    var imageChange = function() {
        $(this).find('.headshot-alt').toggleClass('-hide');
        $(this).find('.headshot-main').toggleClass('-hide');
        console.log('alt image found');
    }

    $('.m-team-image').click(imageChange).hover(imageChange);
});