import 'jquery';

jQuery('#basketOverview').delay(5000).fadeOut(400);

jQuery(document).on('mouseup', function (e)
{
    var basket = jQuery('#basketOverview');

    if (!basket.is(e.target) && basket.has(e.target).length === 0)
    {
        removeBasketBox();
    }
});

function removeBasketBox(){
    jQuery("#basketOverview").css("display","none");
};