import { default as $ } from 'jquery';

// Filter functions
    $("#filters li").on("click", function(){
        var filtertag = $(this).attr('id');
        $('.m-gallery-section').hide().filter('.' + filtertag).show();
    });
    
    $("#allImages").on("click", function(){
        $('.m-gallery-section').show();
    });
    
    $(".m-gallery-filter-item").click(function(){
        $("#filters").slideToggle();
    });
    
     $(document).on("click", function(event){
        var $trigger = $(".m-gallery-filter-item");
        if($trigger !== event.target && !$trigger.has(event.target).length){
            $("#filters").slideUp();
        }
    });


// Lightbox function
    function openLightBox() {
        $(".m-gallery-section .m-gallery-button").on('click', function(){
        if($(this).closest(".m-gallery-section").hasClass("-noShow")) {
            console.log('got here');
            $(".m-gallery-section").addClass("-noShow");
            $(this).closest(".m-gallery-section").removeClass("-noShow");
            $(".m-gallery-lightbox").hide();
            $(this).closest(".m-gallery-section").find(".m-gallery-lightbox").show();
        }else{
            $(".m-gallery-section").addClass("-noShow");
            $(".m-gallery-lightbox").hide();
            console.log('went to else');
        }
    })

    $(document).on('click',function(e){
        if(!(($(e.target).closest(".m-gallery-lightbox").length > 0 ) || ($(e.target).closest(".m-gallery-button").length > 0))){
            $(".m-gallery-lightbox").hide();
            $(".m-gallery-section").addClass("-noShow");
    }
    });
    };

    $(document).ready(function(){
        openLightBox();
    });